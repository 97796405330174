import './content1.scss';
import contentImg1 from '../../../img/page-contant/content1/ct1.jpg';
import contentImg1Mob from '../../../img/page-contant/content1/ct1-mob.jpg';
const Content1 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'content1');
 
	contentElement.innerHTML = `

	<div class='content1__container'>
	<img src= "${contentImg1Mob}" class="content1__img-mob" width="432" height="210">
	<img src= "${contentImg1}" width="418" height="720" class="content1__img">
		
	<div class='content1__main'>
		<h2 class="content1__title">
Как заработать пассивно
		</h2>
		<p class="content1__text">
			Проект Илона Маска Tesla X инвестиции был создан для того, чтобы каждый человек мог достойно зарабатывать. Платформа была создана на основе искусственного интеллекта, и это позволяет получать профит даже тем, кто совершенно не разбирается в инвестициях. У площадки широкий функционал и интуитивно понятный интерфейс. А еще она может работать в режиме 24/7, помогая зарабатывать трейдеру больше прибыли. Если посмотреть на отзывы о Tesla X программе для заработка, то в них нередко упоминается то, что торговля ведется в автоматическом режиме. И это привлекает к проекту большое количество пользователей. Они могут торговать на платформе даже не обладая соответствующим опытом. Площадка раскрывает перед инвесторами неограниченные возможности. Заработок будет зависеть от суммы инвестиций, и времени торговли. Платформа обладает рядом преимуществ:

		</p>
		<ul class="content1__list">
			<li>работа в автоматическом режиме, без необходимости торговать вручную;
			</li>
			<li>автоматический анализ рынков и мгновенная реакция на изменения;
			</li>
			<li>подбор самых прибыльных стратегий или их составление;
			</li>
			<li>
				работа в круглосуточном режиме, что не под силу трейдеру.
			</li>
			
		</ul>

	</div>

	

	

		



	</div>
	
	`;
 
	return contentElement;
 };
 
 export default Content1;