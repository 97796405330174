
import Content1 from './Content1/content1';
import Content2 from './Content2/content2';
import Content3 from './Content3/content3';
import Content4 from './Content4/content4';
export{
	Content1,
	Content2,
	Content3,
	Content4
}