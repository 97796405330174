import './contents4.scss';
import contentImg3 from '../../../img/page-contant-2/content2-4/ct2222.jpg';
import contentImg3mob from '../../../img/page-contant-2/content2-4/ct2222-mob.jpg'

const Contents4 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'contents4');
 
	contentElement.innerHTML = `
	<div class='contents4__container'>
	<img class="contents4__img-mob" width="432" height="210" src="${contentImg3mob}">
	
	
	<div class="contents4__body">
	<h2 class="contents4__title">
Как зарабатывают инвесторы

	</h2>
		<p class="contents4__text">
			Платформа сама проведет анализ текущей ситуации, проверит перспективы развития тех или иных активов и сделает самостоятельный вывод о том, какие именно операции нужно проводить прямо сейчас. Платформа обладает уникальными свойствами - использует различные инструменты для торговли, полностью автоматизирована, проводит точный анализ и выстраивает прогнозы, заключает только прибыльные сделки, создает депозиты с различным сроком действия, составляет эффективный инвестиционный портфель, компания постоянно модернизируется и совершенствуется, обеспечивает надежную защиту личных данных.
			<br>С этой платформой у трейдеров появилась возможность высвободить время для отдыха с семьей, занятий спортом или хобби. Автоматическая торговля Amazon акциями приносит инвесторам стабильно высокий доход. Программа позволяет инвесторам создать свою уникальную стратегию по заработку в интернете. С ее помощью инвесторы накапливают личный капитал и могут эффективно управлять инструментами в своем портфеле.
		</p>

	</div>
		
	<img class="contents4__img" width="1180" height="400" src="${contentImg3}">
	
	</div>
	
	`;
 
	return contentElement;
 };
 
 export default Contents4;