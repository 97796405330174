import './contents2.scss';
import contentImg3 from '../../../img/page-contant-2/content2-2/ct22.jpg';
import contentImg3mob from '../../../img/page-contant-2/content2-2/ct22-mob.jpg'

const Contents2 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'contents2');
 
	contentElement.innerHTML = `
	<div class='contents2__container'>
	<img class="contents2__img-mob" width="432" height="210" src="${contentImg3mob}">
	<img class="contents2__img" width="1180" height="400" src="${contentImg3}">
	
	<div class="contents2__body">
	<h2 class="contents2__title">
	Как зарабатывать токены на автоматической платформе
	</h2>
		<p class="contents2__text">
		Среди вариантов того как заработать на NFT токенах, следует уделить внимание автоматическому трейдингу. Платформа с искусственным интеллектом позволяет свести к минимуму человеческий фактор в торговле. Программа работает по заложенным в нее алгоритмам, и это исключает ошибки, которые совершают трейдеры на эмоциях. ИИ может не только открывать и закрывать сделки. Он анализирует рынки, мониторит тренды, составляет стратегии для торговли. Это открывает широкие возможности для трейдеров разного уровня подготовки – профессионалов, новичков. Автоторговля имеет множество плюсов и среди них следует назвать - возможность инвестировать на долгий или короткий срок, диверсификация инвестиционного портфеля, что позволяет максимизировать заработок, а также избежать убытков, автоматический анализ рынков и мгновенная реакция на любое изменение, которая позволяет открывать и закрывать ордера по выгодным ценам, при автоматической торговле исключаются ошибки, основанные на эмоциях, таких как жадность, паника.

		</p>
	</div>
		

	
	</div>
	
	`;
 
	return contentElement;
 };
 
 export default Contents2;