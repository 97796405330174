import './contentt2.scss';
import contentImg from '../../../img/page-content-3/ct33.jpg';
import contentImgMob from '../../../img/page-content-3/ct33-mob.jpg';

const Contentt2 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'contentt2');
 
	contentElement.innerHTML = `
	<div class='contentt2__container'>
	
	
	<div class="contentt2__body">
	<h2 class="contentt2__title">

	На платформе нужно торговать самостоятельно или есть возможность автоматической торговли?

	</h2>
		<p class="contentt2__text">
		Даже если человек обладает знаниями в сфере инвестиций, ему необходимо консультироваться с профессионалами. Специалисты Маргулан советуют как правильно выбрать проект, стратегии, помогают с анализом рынка. Новички могут отдать свои активы в доверительное управление. Они будут получать пассивный доход, выплачивая небольшой процент компании. Заработок на трейдинге привлекает многих инвесторов. Но не каждый может разобраться в этом процессе. А некоторые люди боятся потерять свои вложения, потому что у них нет достаточно практики или знаний. Курсы Маргулан Сейсембаева помогают достичь профессионализма в трейдинге, а наставники поддерживают учеников во время практики и обучения. Однако не для всех подходит такой заработок. У кого-то не хватает на торговлю времени. И в этом случае есть возможность зарабатывать в автоматическом режиме, настроив терминал. Это даст возможность получать прибыль, практически не вмешиваясь в торговый процесс.

		</p>
<img class="contentt2__img-mob" width="432" height="210" src="${contentImgMob}">
	
	</div>
		

	<img class="contentt2__img" width="600" height="882" src="${contentImg}">
	</div>
	<div class="contentt2__container">
		<p class="contentt2__text">
		У автоматического трейдинга есть множество позитивных моментов. Во-первых, пользователю не нужно самостоятельно открывать и закрывать сделки, потому что за него это сделает бот. Во-вторых, торговая платформа, созданная на основе ИИ, работает без выходных и сна, что приносит инвестору еще больше дохода. В-третьих, бот не имеет эмоций, и это важно для трейдинга, потому что чаще всего убытки несут инвесторы под их влиянием. Также следует отметить, что подбор правильной стратегии обеспечит хорошую доходность. На платформе есть возможность открывать одновременно несколько сделок и обрабатывать большое количество информации. Она самостоятельно проводит анализ рынка и мгновенно реагирует на все его изменения.

		</p>

		<p class="contentt2__text">
		Автоматическая торговля подойдет не только для новичков и тех, кто хочет зарабатывать пассивно. ПО нередко используют опытные трейдеры, запуская его в ночное время. Таким образом они получают доход не только при ручной торговле, но и во то время, когда у них нет возможности это делать (например, во время сна или поездки в машине). Настройка платформы тоже требует наличия знаний, и на курсе Маргулана Сейсембаева о инвестировании будет предоставлена информация о том как это делается.

		</p>
		<p class="contentt2__text">
		Многие пользователи задают вопрос на Маргулан как зарабатывать с телефона? Для этого существует приложение. Программа доступна для скачивания на App Store и Google Play и подходит для смартфонов с операционной системой Android, iOS. Также трейдеры могут заходить на официальный сайт, используя ПК.Заработок на инвестициях возможен при соблюдении дисциплины, тщательном планировании, проведении анализа рынка. Здесь важно определить цели, оценить риски, диверсифицировать портфель. Без постоянного обучения позитивного результата не добиться. Специалисты Маргулан кейсы помогают составлять пользователям, а также оказывают поддержку при торговле.
		</p>
	</div>
	
	`;
 
	return contentElement;
 };
 
 export default Contentt2;