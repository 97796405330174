import './contentf3.scss';

const Contentf3 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'contentf3');
 
	contentElement.innerHTML = `
	<div class='contentf3__container'>
	
	
	<div class="contentf3__body">
	<h2 class="contentf3__title">
	Как заработать на инвестициях и не уйти в минус

	</h2>
		<p class="contentf3__text">
		Обучение дает множество преимуществ - появляется понимание того как работает фондовый рынок, проводится аналитика. Пользователь узнает, какие технологии используются для автоматической торговли, изучается аналитика и выбор стратегии, которая поможет заработать деньги. Трейдеры набираются опыта в ручной торговле и получают информацию о том как настроить платформу для автоматической торговли, развивается эмоциональный контроль, необходимый для трейдинга. Инвесторы применяют на практике знания под руководством опытных наставников. Финансовые рынки постоянно меняются, поэтому важно быть в курсе новостей и событий, которые могут повлиять на получения инвестиций.

		</p>
        <p class="contentf3__text">
		Обучение Маргулан Инвестиции поможет повысить финансовую грамотность, выйти на гарантированный высокий заработок. В отзывах о Маргулан трейдеры пишут о том, что обучение им помогло правильно проводить анализ рынка и изучить активы. Также специалисты компании много уделяют времени изучению рыночных трендов. Курс подходит для новичков и опытных инвесторов. Он рассчитан на разные категории людей – тех, кто уже знаком с биржевой торговлей, и пользователей, только начинающих свой путь в этом деле. Изучение трейдинга не принесет хороших результатов, если не применять знания на практике. Этому команда компании уделяет много времени. Зарегистрировавшиеся на сайте пользователи смогут тренироваться на реальном счете под контролем опытных инвесторов и зарабатывать деньги.
		
		</p>
        <p class="contentf3__text">
	
		У компании Margulan Invest сложилась хорошая репутация. Ее команда состоит из опытных финансистов и инвесторов. Под их руководством пользователи получат знания о том, как работает фондовый рынок, выбирается стратегия, проводится анализа сигналов, рынка, трендов. Курс поможет разобраться с тем какие инструменты лучше выбирать для торговли, как получать прибыль на криптовалюте и не уйти в минус на сделках. Для начала работы с сайтом пользователям необходимо выполнить несколько шагов - оставить на ресурсе Маргулана Сейсембаева контакты, создать личный кабинет, ответить на звонок сотрудника компании. Во время разговора будет выяснено уровень знания пользователя и подобрана лучшая программа по заработку и обучение.
		</p>
        
	</div>
		
    
	
	</div>
	
	`;
 
	return contentElement;
 };
 
 export default Contentf3;