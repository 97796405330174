export const setupMenu = () => {
    const burger = document.querySelector('.header__burger');
    const nav = document.querySelector('.header__nav');

    if (burger && nav) {
        burger.addEventListener('click', () => {
            nav.classList.toggle('header__nav--active');
            burger.classList.toggle('header__burger--active');
        });

        const navLinks = document.querySelectorAll('.nav__link');
        navLinks.forEach(link => {
            link.addEventListener('click', (event) => {
                event.preventDefault();
                const targetClass = link.getAttribute('data-target');
                const targetElement = document.querySelector(targetClass);
                if (targetElement) {
                    targetElement.scrollIntoView({
                        top: targetElement.offsetTop,
                        behavior: 'smooth'
                    });

                    // Закрытие меню при нажатии на ссылку
                    nav.classList.remove('header__nav--active');
                    burger.classList.remove('header__burger--active');
                } else {
                    console.warn(`Element with class ${targetClass} not found`);
                }
            });
        });
    } else {
        console.error('Элемент .header__burger или .header__nav не найден');
    }
};
