import './content4.scss';
import contentImg3 from '../../../img/page-contant/content4/ct1111.jpg';
import contentImg3mob from '../../../img/page-contant/content4/ct1111-mob.jpg'

const Content4 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'content4');
 
	contentElement.innerHTML = `
	<div class='content4__container'>
	<img class="content4__img-mob" width="432" height="210" src="${contentImg3mob}">
	<img class="content4__img" width="1180" height="310" src="${contentImg3}">
	
	<div class="content4__body">
	<h2 class="content4__title">

	Какие способы заработка NFT существуют
	</h2>
		<p class="content4__text">
		Существует несколько способов того, на NFT как заработать. И один из них – торговля токенами на бирже. Для этого трейдер может использовать автоматическую платформу и сделать заработок стабильным. О том как заработать на NFT говорится много в сети. Существует несколько вариантов заработка на НФТ - создание и продажа своих собственных цифровых произведений искусств, музыки, видео или других форм контента на различных платформах, трейдинг для получения прибыли от разницы в цене, что похоже на торговлю акциями или криптовалютами, где покупатели надеются, что цена на токены возрастет.
		
		</p>
	<p class="content4__text">
	Также есть роялти на платформах, которые позволяют создателям получать процент от последующих продаж их токенов, некоторые платформы позволяют владельцам NFT сдавать их в аренду или использовать их для получения дохода от ставок. Часть платформ предлагает партнерские программы, которые позволяют зарабатывать, привлекая новых пользователей и продавцов на сайт, разработка NFT, которые имеют практическое применение, например, для доступа к эксклюзивным контентам, мероприятиям или в играх. Каждый из этих способов имеет свои риски и требует определенных знаний и навыков.
	
	</p>

	</div>
		

	
	</div>
	
	`;
 
	return contentElement;
 };
 
 export default Content4;