import './contents3.scss';
import contentImg3 from '../../../img/page-contant-2/content2-3/ct222.jpg';
import contentImg3mob from '../../../img/page-contant-2/content2-3/ct222-mob.jpg'

const Contents3 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'contents3');
 
	contentElement.innerHTML = `
	<div class='contents3__container'>
	<img class="contents3__img-mob" width="432" height="210" src="${contentImg3mob}">
	<img class="contents3__img" width="580" height="650" src="${contentImg3}">
	
	<div class="contents3__body">
	<h2 class="contents3__title">

	Как работают инвестиционные программы на платформе Gemini Grand

	</h2>
		<p class="contents3__text">
		Автоматический заработок в Gemini Grand привлекает много новых инвесторов и трейдеров. Программа разрабатывает новые торговые стратегии, анализирует сделки и принимает самостоятельные решения по покупке или продаже активов. Площадка поддерживает около 80 различных криптовалют и стейблкоинов. Кроме того, у платформы есть собственная криптовалюта GUSD, конкурирующая с USDT. Как заработать с Gemini Grand? Первый шаг – это регистрация на сайте. После пополнения счета становится доступной сама платформа с автоматизированной системой для проведения торговых операций на фондовых рынках. ПО настроено на проведение успешных торговых операций с целью увеличения доходов инвесторов и трейдеров. Она проводит анализ торговых сделок, мониторит экономические новости и делает прогнозы на возможный рост или падение криптовалюты.
		</p>

	</div>
		

	
	</div>
	
	`;
 
	return contentElement;
 };
 
 export default Contents3;