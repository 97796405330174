import './hero4.scss';
import heroImg from '../../../img/hero-4.jpg';
import heroMobImg from '../../../img/hero-4-mob.jpg';
const Hero4 = () => {
	const heroElement = document.createElement('div');
	heroElement.classList.add('hero', 'hero-2');
 
	heroElement.innerHTML = `
	<div class='hero__container'>
	<img class='hero__img' src=${heroImg}>
	<img class="hero__mob-img" src="${heroMobImg}">
	<div class="hero__contant">
	
		<h3 class="hero__subtitle">

	
		</h3>
		<h1 class="hero__title">
Imperial Oil – как получить профит в долгосрочной и краткосрочной перспективе

		</h1>

	<p class="hero__text">
Imperial Oil инвестиции – это отличный выбор для тех, кто хочет получать стабильный профит.
		
	</p>
	
</div>
		 

	</div>

	<div class="hero__container">
		<form method="POST" action="#" autocomplete="off" class="lead-form consult-form" data-redirect="true"
		data-redirect-url="thank-you.html">
		<input type="text" name="name" id="name" placeholder="Ваше Имя" required>
		<input type="email" name="email" id="email" placeholder=" Ваша почта" required>
		<input type="tel" name="phone" id="phone" style="z-index:99;" placeholder="Телефон" required>
		<button class="consult-btn" type="submit">Отправить</button>
		<ul style="color:red; position:relative; z-index:10;" class="messages"></ul>
	</form>
	</div>
	
	`;
 
	return heroElement;
 };
 
 export default Hero4;