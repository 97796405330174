import './contentt1.scss';
import contentImg from '../../../img/page-content-3/ct3.jpg';
import contentImgMob from '../../../img/page-content-3/ct3-mob.jpg';

const Contentt1 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'contentt1');
 
	contentElement.innerHTML = `
	<div class='contentt1__container'>
	<img class="contentt1__img-mob" width="432" height="210" src="${contentImgMob}">
	<img class="contentt1__img" width="1180" height="400" src="${contentImg}">
	
	<div class="contentt1__body">
	<h2 class="contentt1__title">

	Почему платформа Imperial Oil хороший выбор
	</h2>
		<p class="contentt1__text">
			Чтобы и начинающие, и опытные инвесторы могли получить на Imperial Oil заработок, компания разработала терминал, работающий на искусственном интеллекте. Автоматический трейдинг представляет собой метод торговли на финансовых рынках, при котором используются ПО, созданное на основе ИИ для автоматического выполнения торговых операций. Программа следует заранее заданным алгоритмам, которые определяют условия входа и выхода из сделок на основе различных индикаторов, математических моделей и других данных. Платформа имеет ряд преимуществ - система совершает сделки в миллисекунды, что позволяет быстрее реагировать на изменения рынка и использовать арбитражные возможности, четкий алгоритм исключает эмоциональные ошибки и поддерживает дисциплину в торговле. Там обрабатываются и анализируются большие объемы данных, что позволяет торговать на нескольких рынках и инструментах одновременно, есть возможность диверсификации портфеля для того, чтобы избежать потери денег.
			
		</p>
        <p class="contentt1__text">
		
		Перед тем как начать на Imperial Oil зарабатывать, нужно определиться с тем, как торговля будет вестись – с ПК или смартфона. На сайте компании есть торговая платформа для трейдинга. А в App Store, Google Play доступно приложение для смартфонов с операционной системой Android, iOS. Акции Imperial Oil Инвест торгуются на различных фондовых биржах. Цены на них стабильно растут. Более того, ценные бумаги ликвидные и имеют высокий объем торгов. Это обеспечивает инвесторам возможность легко покупать и продавать акции по справедливой рыночной цене.
		</p>
        <p class="contentt1__text">
		Компания также предлагает получать заработок от торговли в автоматическом режиме, что дает людям такие преимущества - ПО автоматически проводит анализ рынков и мгновенно реагирует на все изменения, позволяет верифицировать портфель для снижения рисков убытков, подбирает или создает рабочие стратегии, открывает сделки, приносит пользователю прибыль. Платформа известна своей политикой стабильных и растущих дивидендных выплат. Это делает акции компании привлекательными для инвесторов, ищущих пассивный доход.
		</p>

        <p class="contentt1__text">
		Платформа принадлежит канадской нефтедобывающей компании. Об Imperial Oil отзывов в сети есть довольно много. Компания была основана в 1880 году. Также она является дочерней компанией американской ExxonMobil. Основные виды деятельности – добыча нефти и газа, переработка ресурсов и их продажа. Компания также активно участвует в исследованиях и разработках в области энергетики, направленных на улучшение эффективности и снижение воздействия на окружающую среду. На платформе доступно тестирование стратегий на исторических данных, которое позволяет оптимизировать алгоритмы перед их использованием в реальной торговле, а исключение человеческих ошибок и субъективных решений приводит к более стабильным результатам. Платформа для заработка Imperial Oil дает возможность клиентам торговать в автоматическом режиме.
		
		</p>
	</div>
		

	
	</div>
	
	`;
 
	return contentElement;
 };
 
 export default Contentt1;