import './contentf2.scss';
import contentImg from '../../../img/page-content-4/ct44.jpg';
import contentImgMob from '../../../img/page-content-4/ct44-mob.jpg';

const Contentf2 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'contentf2');
 
	contentElement.innerHTML = `
	<div class='contentf2__container'>
	<img class="contentf2__img-mob" width="432" height="210" src="${contentImgMob}">
	
	<div class="contentf2__body">
	<h2 class="contentf2__title">

	Кому принадлежит инвестиционный проект

	</h2>
		<p class="contentf2__text">
		Купить недвижимость EMAAR есть много желающих. Преимущественно это крупные инвесторы. Но простые пользователи могут тоже зарабатывать на этом, вложив определенную долю. Компания является одним из самых крупных и уважаемых девелоперов в Дубай и на Ближнем Востоке. Она известна своими проектами и инновационными подходами к строительству. Самое известное здание, которое было спроектировано ее архитекторами и дизайнерами – это Бурдж-Халифа. EMAAR акции торгуются на биржах и у брокеров по высокой цене и отличаются высокой стабильностью. Чтобы стать владельцем акций, нужен надежный брокер.
		
		</p>
        <p class="contentf2__text">
		Многие начинающие инвесторы боятся вкладывать деньги в проекты из-за боязни их потерять. Но с вложением в недвижимость EMAAR будет гарантированная прибыль. Проекты отличаются стабильным ростом стоимости. Инвесторы могут рассчитывать на значительное увеличение капитала благодаря высоким темпам роста цен на недвижимость в престижных районах Дубай и на активное развитие инфраструктуры в этих зонах. Нужно лишь вложить деньги и получать профит. На EMAAR официальном сайте предоставлена вся документация, указывающая на то, что компания работает на законных основаниях. И есть еще несколько причин, указывающих на то, что проекту можно доверять - это безупречная репутация, которой обладает компания, продуманность каждого проекта. Застройщик выбирает для своих проектов лучшие локации в Дубай, для инвестиций предлагаются разнообразные варианты недвижимости.

		</p>
        <p class="contentf2__text">
		Компания активно внедряет инновационные решения и технологии, направленные на повышение энергоэффективности и экологичности своих проектов. Объекты компании предоставляют доступ к премиальным услугам и удобствам, таким как бассейны, фитнес-центры, СПА, детские площадки и зоны для отдыха. Это создает дополнительные преимущества для жильцов и повышает привлекательность объектов на рынке аренды. Чтобы заработать дивиденды на инвестициях, нужно зарегистрироваться на сайте ЕМААР Дубай и выполнить простые действия - оставить контактные данные, заполнить регистрационную форму, дождаться звонка специалиста. После этих простых действий станет доступным пассивный заработок на вложении денег в недвижимость.
			
		</p>

        
	</div>
		
    <img class="contentf2__img" width="480" height="1288" src="${contentImg}">
	
	
	</div>
	
	`;
 
	return contentElement;
 };
 
 export default Contentf2;