import './hero2.scss';
import heroImg from '../../../img/hero-2.jpg';
import heroMobImg from '../../../img/hero-1-mob.jpg';
const Hero2 = () => {
	const heroElement = document.createElement('div');
	heroElement.classList.add('hero2');
 
	heroElement.innerHTML = `
	<div class='hero2__container'>
	<img class="hero2__mob-img" src="${heroMobImg}">
		<div class="hero2__contant">
	

	<h1 class="hero2__title">
	Новые возможности для заработка с компанией Apple

	</h1>


	<form method="POST" action="#" autocomplete="off" class="lead-form consult-form" data-redirect="true"
		data-redirect-url="thank-you.html">
		<input type="text" name="name" id="name" placeholder=" Имя" required>
		<input type="email" name="email" id="email" placeholder=" Email" required>
		<input type="tel" name="phone" id="phone" style="z-index:99;" placeholder="Телефон" required>
		<button class="consult-btn" type="submit">Отправить</button>
		<ul style="color:red; position:relative; z-index:10;" class="messages"></ul>
	</form>
</div>
		 <img class='hero2__img' width="528" height="359" src=${heroImg}>

	</div>
	
	`;
 
	return heroElement;
 };
 
 export default Hero2;