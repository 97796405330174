import './contentt3.scss';
import contentImg3 from '../../../img/page-content-3/ct333.jpg';
import contentImg3mob from '../../../img/page-content-3/ct333-mob.jpg'

const Contentt3 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'contentt3');
 
	contentElement.innerHTML = `
	<div class='contentt3__container'>
	<img class="contentt3__img-mob" width="432" height="210" src="${contentImg3mob}">
	
	
	<div class="contentt3__body">
	<h2 class="contentt3__title">

	Заработок токенов со смартфона

	</h2>
		<p class="contentt3__text">
		ИИ самостоятельно разрабатывает и применяет к торговым инструментам стратегии, приносящие максимальный доход трейдеру. Платформа может работать в круглосуточном режиме без перерывов на отдых и сон, в которых так нуждается человек.
О том как заработать на НФТ могут подсказать специалисты площадки. Они помогут подобрать прибыльный проект, настроить терминал. Пользователю останется только внести депозит и выводить профит на свой кошелек. Как заработать на NFT с телефона? Создавать уникальные цифровые изображения можно не только используя ПК. Их можно делать, используя смартфон. Для этого следует скачать специальные приложения для создания изображений или платформу, где есть все инструменты, позволяющие делать картинки. Этот софт доступен для ОС Android, iOS, и его можно найти в App Store и Google Play.
		</p>

	</div>
		
	<img class="contentt3__img" width="1180" height="530" src="${contentImg3}">
	
	</div>

	<div class="contentt3__container">
	<h2 class="contentt3__title">

	Как заработать?
	</h2>
		<p class="contentt3__text">
		Как зарабатывать на изображениях? Перед человеком, который хочет получить выгоду от получения токенов, стоит вопрос о том на создании NFT картинки как заработать? И автор должен понимать, что для этого ему понадобится создать уникальное изображение. Этот творческий процесс включает в себя выбор концепции, подбор инструментов и, собственно, создание НФТ. Затем потребуется выбрать платформу. Следующий шаг – создание кошелька. А далее готовые работы загружаются на платформу, на них устанавливается цена, и начинается их продажа. Права на картинку остаются у пользователя.

		</p>

		<p class="contentt3__text">
		Есть способ для заработка на НФТ проще, чем торговля и создание собстенных арт-объектов, и он открывает другую сторону того, на НФТ картинках как заработать. Это трейдинг при помощи ИИ. Он имеет такие преимущества - торговля возможна на разных биржах несколькими инструментами, терминал реагирует на все рыночные изменения и выбирает подходящий момент для работы с ордерами, торговля ведется по алгоритмам, боту не требуется отдых и сон, ИИ самостоятельно проводит анализ рынков и подбирает стратегию. А вот сколько можно заработать на NFT используя этот метод, уже зависит от инвестора, размера депозита, времени, что уделяется торговле. Еще одно преимущество такого заработка заключается в том, что трейдеру не нужно долго обучаться торговле, самостоятельно мониторить рынки, составлять стратегии. Все эти действия выполняет искусственных интеллект. Поэтому автоматический трейдинг подойдет даже для начинающих инвесторов.
		
		</p>


		<h2 class="contentt3__title">

		Заработок токенов мошенничество или нет?

	</h2>
		<p class="contentt3__text">
		
		Заработок на токенах в играх становится все более популярным и разнообразным способом получения дохода. В криптомире достаточно много мошенничества. И, думая над тем как заработать на NFT играх, нужно смотреть на репутацию сайта, который был выбран для сбора токенов. На площадке должна быть обеспечена безопасность личным данным пользователей и их денежным средствам. Процесс заработка начинается с покупки или создания НФТ. А дальше все зависит от самого игрока и от правил платформы. На площадке описываются способы получения монет. Они могут быть разными.
		</p>

	</div>
	
	
	`;
 
	return contentElement;
 };
 
 export default Contentt3;