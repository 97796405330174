import './contentt4.scss';
// import contentImg from '../../../img/page-content-3/ct3333.jpg';
// import contentImgMob from '../../../img/page-content-3/ct3333-mob.jpg';
// import contentImg2 from '../../../img/page-content-3/ct444/ct444-2.jpg';
// import contentImg2mob from '../../../img/page-content-3/ct444/ct444-mob-2.jpg';
// import contentImg3 from '../../../img/page-content-3/ct444/ct444-3.jpg';
// import contentImg3mob from '../../../img/page-content-3/ct444/ct444-mob-3.jpg';

const Contentt4 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'contentt4');
 
	contentElement.innerHTML = `
	<div class='contentt4__container'>
	<img class="contentt4__img-mob" width="432" height="210" src="${contentImgMob}">
	<img class="contentt4__img" width="1180" height="530" src="${contentImg}">
	
	<div class="contentt4__body">
	<h2 class="contentt4__title">

	Насколько привлекательны инвестиции в недвижимость в Дубай

	</h2>
		<p class="contentt4__text">
		Дубай характеризуется высоким уровнем политической стабильности и безопасности, строгие законы и порядок создают благоприятные условия для ведения бизнеса и инвестиций, а высокий уровень жизни и широкий спектр услуг привлекают сюда туристов, что поддерживает стабильный спрос на недвижимость. Цены на недвижимость в Дубай имеют тенденцию к росту, особенно в премиальных районах города, и вложенные средства могут существенно увеличиться за счет роста рынка и развития новых перспективных проектов. Рынок недвижимости предлагает широкий выбор объектов для инвестиций – от роскошных апартаментов и вилл, до коммерческой недвижимости и офисных помещений. Процесс инвестирования денег в недвижимость от застройщика EMAAR упрощен и максимально прозрачен. Иностранные граждане имеют право на приобретение недвижимости в специально отведенных зонах (freehold areas), что делает инвестиции доступными и юридически защищенными.
		
		</p>
	</div>
		

	
	</div>
	
	<div class='contentt2__container'>
	
	
	<div class="contentt2__body">
	<h2 class="contentt2__title">

	Особенности вложения денег в недвижимость

	</h2>
		<p class="contentt2__text">
		Эксперты по недвижимости оставляют положительные о компании EMAAR отзывы. И это не только потому, что застройщик известен своим вниманием к деталям и стремлением к высочайшему качеству. Недвижимость, построенная этой компанией, отличается современным дизайном, передовыми технологиями и высоким уровнем комфорта. Но также компания дает возможность получать прибыль, вкладывая инвестиции в их объекты. Зарабатывать могут все желающие – и новички, и профессиональные инвесторы.
		
		</p>
<img class="contentt2__img-mob" width="432" height="210" src="${contentImg3mob}">
	
	</div>
		

	<img class="contentt2__img" width="560" height="514" src="${contentImg3}">
	</div>
	<div class="contentt4__container">
	<p class="contentt4__text"> 
	
	Есть ряд причин, по которым следует доверить свой капитал этой компании - это надежность, проверенная годами, и безупречная репутация, продуманные проекты самого высокого качества, строительство объектов в престижных локациях, разнообразие проектов EMAAR – от СПА-салонов до офисных помещений и жилья, высокая доходность от аренды, инновации и оригинальные архитектурные решения при строительстве объектов. Компания предлагает услуги управления недвижимостью, что особенно важно для иностранных инвесторов, не проживающих постоянно в ОАЭ. Это гарантирует сохранность и эффективное управление их инвестициями.
	</p>
	</div>

	<div class='contentt2__container'>
	<img class="contentt2__img" width="560" height="514" src="${contentImg2}">
	
	
	<div class="contentt2__body">
	<h2 class="contentt2__title">

	Можно ли торговать со смартфона

	</h2>
		<p class="contentt2__text">
		На EMAAR Dubai официальном сайте доступна ссылка на приложение, позволяющее контролировать свои инвестиции. Его можно найти в App Store, Google Play. Оно разработано для мобильных устройств, работающих на операционной системе Android, iOS. Стоит ли вкладывать деньги в недвижимость EMAAR в Дубай? Да, стоит. Это пассивный доход, который будет стабильно расти и увеличиваться. Компания часто предлагает гибкие условия оплаты и рассрочки для своих клиентов, что делает инвестиции более доступными. Для начала работы заключается договор, создается личный кабинет, подбираются объекты для инвестирования, вносится депозит на площадку, получается заработок с инвестиций. Объекты для инвестирования находятся не только в Дубай. Здания ЕМААР в Стамбуле тоже есть. И они доступны для инвестиций.
		
		</p>
<img class="contentt2__img-mob" width="432" height="210" src="${contentImg2mob}">
	
	</div>
		

	
	</div>
	`;
 
	return contentElement;
 };
 
 export default Contentt4;