import './content2.scss';
// import contentImg2 from '../../../img/page-contant/content2/ct2.jpg';
// import contentImg2mob from '../../../img/page-contant/content2/ct2-mob.jpg'

const Content2 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'content2');
 
	contentElement.innerHTML = `
	<div class='content2__container'>
	<img class="content2__img-mob" width="432" height="210" src="${contentImg2mob}">

	
	<div class="content2__body">
	<h2 class="content2__title">
Почему лучше торговать в автоматическом режиме

	</h2>
		<p class="content2__text">
		Торговая площадка лишена эмоциональных проявлений, что предотвращает принятие необоснованных решений. Эти возможности делают WTBot инвест мощным инструментом, который помогает получать доход на трейдинге всем инвесторам, и это не зависит от уровня их подготовки. 
Получать прибыль на биржах и брокерских сайтах можно, занимаясь ручной торговлей, но в данном случае пользователю необходимо обладать широким спектром знаний в этой сфере и иметь за плечами большой опыт торговли. Есть еще один способ – инвестирование. Это могут быть акции или другие торговые инструменты. Однако желаемого уровня дохода будет добиться сложно. На площадке WTBot заработок будет стабильным и высоким, а все потому что весь процесс контролирует робот. Эта система позволяет получать стабильный и высокий профит и не требует почти никаких действий со стороны инвестора.
		</p>

	</div>
		<img class="content2__img" width="444" height="397" src="${contentImg2}">

	
	</div>
	
	`;
 
	return contentElement;
 };
 
 export default Content2;