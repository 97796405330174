import './contentf1.scss';
import contentImg from '../../../img/page-content-4/ct4.jpg';
import contentImgMob from '../../../img/page-content-4/ct4-mob.jpg';

const Contentf1 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'contentf1');
 
	contentElement.innerHTML = `
	<div class='contentf1__container'>
	
	<div class="contentf1__body">
	<h2 class="contentf1__title">

	Действительно ли есть заработок на НФТ?

	</h2>
		<p class="contentf1__text">
		В сети везде встречаются отзывы о том, что кто-то получил профит на НФТ. А можно ли заработать на NFT в реальности? На это можно ответить утвердительно. Если речь идет о создании картинок или другого контента, то тут есть риск, что они не будут продаваться, и тогда о доходе остается только мечтать. Другое дело – трейдинг. На платформах с искусственным интеллектом пользователи будут гарантированно получать доход. Причем заработок доступен для каждого человека. Разбираясь с тем как инвестировать в NFT чтобы это принесло прибыль, нужно тщательно изучить все способы. Множество инвесторов обращают все больше внимания на автоматические платформы, работающие на основе искусственного интеллекта. И в этом нет ничего странного, потому что такие площадки имеют превосходство перед другими способами заработка - при торговле не требуется участие трейдера, платформа автоматически проводит анализ рынка, ИИ выбирает подходящую стратегию для торговли и инструменты, возможность диверсификации портфеля, отсутствие эмоций.
		</p>
        <p class="contentf1__text">
		Заработок в автоматическом режиме дает возможность получить в большинстве случаев профит. И это выгодно для инвесторов. Им не нужно проходить сложное обучение, практиковаться самостоятельно на биржах, теряя деньги на не прибыльных сделках. После настройки платформы, пользователю не нужно будет самостоятельно делать ничего, кроме как выводить заработанный профит. Искусственный интеллект самостоятельно выбирает ликвидные активы для торговли, анализирует большие объемы информации, необходимой для создания прибыльных стратегий, открывает и закрывает сделки, и мгновенно реагирует на малейшие изменения рынка, что позволяет избежать потери денег при трейдинге.

		</p>
        <p class="contentf1__text">

		Но что нужно для того, чтобы стать успешным инвестором и начать использовать эту платформу? Начать зарабатывать на токенах совсем не сложно. Главное, выполнить несколько простых шагов, что займет не более нескольких минут. Пользователю потребуется зарегистрироваться на сайте и создать личный кабинет, оставить контактный номер телефона и адрес электронной почты, дождаться звонка от специалиста. Команда площадки может помочь с настройкой платформы, а также выбором ликвидных инструментов для торговли.
			
		</p>

        
	</div>
		
    <img class="contentf1__img" width="1180" height="650" src="${contentImg}">
	<img class="contentf1__img-mob" width="432" height="210" src="${contentImgMob}">
	
	
	</div>
	
	`;
 
	return contentElement;
 };
 
 export default Contentf1;