import { Popup1 } from '../components/PagePopup';

export const setupPopup = (app) => {
    const burger = document.querySelector('.header__burger');
    const nav = document.querySelector('.header__nav');
    const button = document.querySelector('#popupBtn');

    if (button) {
        button.addEventListener('click', (event) => {
            nav.classList.remove('header__nav--active');
            burger.classList.remove('header__burger--active');
            event.preventDefault();
            Popup1(app);
        });
    }

    setTimeout(() => {
        Popup1(app);
    }, 10000);
};
