import './content3.scss';
import contentImg3 from '../../../img/page-contant/content3/ct111.jpg';
import contentImg3mob from '../../../img/page-contant/content3/ct111-mob.jpg'

const Content3 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'content3');
 
	contentElement.innerHTML = `
	<div class='content3__container'>
	<img class="content3__img-mob" width="432" height="210" src="${contentImg3mob}">
	<img class="content3__img" width="464" height="464" src="${contentImg3}">
	
	<div class="content3__body">
	<h2 class="content3__title">
	Заработок на недвижимости EMAAR – аргументы «за»
	</h2>
		<p class="content3__text">
		Доход от аренды, налоговые льготы, стабильная экономика и стремительно развивающаяся инфраструктура делают Дубай привлекательным для инвесторов со всего мира. Более подробное знакомство с застройщиком ЭМААР в Дубай позволяет больше узнать о плюсах вложения денег в его проекты. Компания строит объекты во многих городах ОАЭ. Однако самыми прибыльными считаются здания в одном из самых крупных городов этого государства – Дубай. Инвестиции в недвижимость в этом городе (отели, коммерческая недвижимость, Спа-салоны) привлекают внимание инвесторов со всего мира. И с каждым годом количество желающих вложить деньги туда только растет.
	</p>

	</div>
		

	
	</div>
	
	`;
 
	return contentElement;
 };
 
 export default Content3;