import './contents1.scss';
import contentImg2 from '../../../img/page-contant-2/content2-1/ct2.jpg';
import contentImg2mob from '../../../img/page-contant-2/content2-1/ct2-mob.jpg'

const Contents1 = () => {
	const contentElement = document.createElement('div');
	contentElement.classList.add('content', 'contents1');
 
	contentElement.innerHTML = `
	<div class='contents1__container'>
	<img class="contents1__img-mob" width="432" height="210" src="${contentImg2mob}">

	
	<div class="contents1__body">
	<h2 class="contents1__title">

	Что дает практика и обучение

	</h2>
		<p class="contents1__text">
		Без понимания самого процесса инвестирования и знаний этой сферы получить прибыль будет сложно, а самостоятельно найти в сети подобную информацию нереально. На платформе для заработка Маргулан трейдерам будет предоставлено обучение и практика. Все полученные знания пользователи имеют возможность проверить на торговой платформе. Там есть возможность сразу применять знания на практике и получать профит, под присмотров наших специалистов. <br>
		Маргулан обучение инвестированию поможет пользователям разобраться со многими нюансами торговли и выйти на гарантированный и стабильный доход. Трейдер узнает как сделать правильный выбор стратегий, которые принесут доход, получит знания о том как умело выбирать торговую площадку и не попасть на удочку мошенников, как зарабатывать на трейдинге в автоматическом режиме, что для этого нужно.
	</p>

	</div>
		<img class="contents1__img" width="580" height="650" src="${contentImg2}">

	
	</div>
	
	`;
 
	return contentElement;
 };
 
 export default Contents1;