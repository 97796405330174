import './hero3.scss';

const Hero3 = () => {
	const heroElement = document.createElement('div');
	heroElement.classList.add('hero3');
 
	heroElement.innerHTML = `
	<div class='hero3__container'>
		<div class="hero3__contant">
	<h3 class="hero3__subtitle">

	
	</h3>
	<h1 class="hero3__title">

	Маргулан Инвест – как гарантированно получать доход на инвестициях

	</h1>
	<p class="hero3__text">
	Прежде чем начинать инвестировать, важно определить свои финансовые цели. Они могут включать накопление на пенсию, покупку недвижимости, достижение финансовой независимости.
	</p>

	<form method="POST" action="#" autocomplete="off" class="lead-form consult-form" data-redirect="true"
		data-redirect-url="thank-you.html">
		<input type="text" name="name" id="name" placeholder="Ваше Имя" required>
		<input type="email" name="email" id="email" placeholder=" Ваша почта" required>
		<input type="tel" name="phone" id="phone" style="z-index:99;" placeholder="Телефон" required>
		<button class="consult-btn" type="submit">Отправить</button>
		<ul style="color:red; position:relative; z-index:10;" class="messages"></ul>
	</form>
</div>
	

	</div>
	
	`;
 
	return heroElement;
 };
 
 export default Hero3;